(function ($) {
  var videoBlockSplitWidthV1 = {
    setup: function ($elements, context) {
      var $modules = $elements;

      $modules.each(function () {
        var $module = $(this);
        var $play = $('.js-play', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isHTML5 = provider === 'html5';

        $play.on('click', function (event) {
          event.preventDefault();

          var opts = {
            context: context,
            provider: provider
          };

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }

          site.videos.open(opts);

          return false;
        });
      });
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */

  $(document).bind('youtubeIframeAPI.loaded', function () {
    var $elements = $('.js-content-block-video--v1').filter("[data-video-provider='youtube']");

    if ($elements.length) {
      videoBlockSplitWidthV1.setup($elements, document);
    }
  });

  Drupal.behaviors.video_block_large_split_width_v1 = {
    attach: function (context, settings) {
      var $elements = $('.js-content-block-video--v1').filter("[data-video-provider='html5']");

      if ($elements.length) {
        videoBlockSplitWidthV1.setup($elements, context);
      }
    }
  };
})(jQuery);
